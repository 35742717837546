import React from "react";
import styled from "styled-components";

import { i18n } from '../lib/misc'

const Wrapper = styled.div`
  margin: 0;
`;

const Number = styled.h2`
  margin: 0;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 16px;
`;

const Counter = ({ count, time, bestScore, locale }) => (
  <>
    <Wrapper>
      <Title>{i18n.TIME[locale]}:</Title> <Number>{time}s</Number>
    </Wrapper>
    <Wrapper>
      <Title>{i18n.SCORE[locale]}:</Title> <Number>{count}</Number>
    </Wrapper>
    {bestScore > 0 && (
      <Wrapper>
        <Title>{i18n.RECORD[locale]}:</Title> <Number>{bestScore}</Number>
      </Wrapper>
    )}
  </>
);

export default Counter;
