import { BW1 } from "./badwords";

const FUCK = locale => BW1.name[locale].toUpperCase();

const RULE_1 = {
  en: color =>
    `Black text: say background color (example: ${color.toUpperCase()})`,
  es: color =>
    `Texto negro: di el color de fondo (ejemplo: ${color.toUpperCase()})`
};

const RULE_2 = {
  en: color =>
    `Colored text: say color of text (example: ${color.toUpperCase()})`,
  es: color =>
    `Texto de color: di el color del texto (ejemplo: ${color.toUpperCase()})`
};

const RULE_3 = {
  en: (locale, badword) =>
    `Swear words: say sweard word (except for the word ${FUCK(
      locale
    )}, see rule 4) (example: ${badword.toUpperCase()})`,
  es: (locale, badword) =>
    `Palabrotas: di la palabrota (excepto la palabra ${FUCK(
      locale
    )}, ver regla 4) (ejemplo: ${badword.toUpperCase()})`
};

const RULE_4 = {
  en: (locale, color) =>
    `${FUCK(locale)} word: you never say this word, rule 1 and 2 apply (example: ${color.toUpperCase()})`,
  es: (locale, color) =>
    `Palabra ${FUCK(
      locale
    )}: nunca tienes que decir esta palabra, aplican las reglas 1 y 2 (ejemplo: ${color.toUpperCase()})`
};

export { RULE_1, RULE_2, RULE_3, RULE_4 };
