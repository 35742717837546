import React, { useState, useEffect } from "react";
import "./reset.css";

import Game from "./Game";

import { RED, BLUE, YELLOW, GREEN, PURPLE, ORANGE, BLACK } from "./lib/colors";
import { BW1, BW2, BW3, BW4 } from "./lib/badwords";

const DEFAULT_LOCALE = "es";
export const DEFAULT_TIME = 59;
export const GAME_TITLE = "F***";

const baseColors = [RED, BLUE, YELLOW, GREEN, PURPLE, ORANGE];
const badwords = [BW1, BW2, BW3, BW4];
const colors = [...baseColors, BLACK, BLACK, BLACK];
const words = (locale) => [
  ...badwords.map(
    (badword) => badword.name[locale] || badword.name[DEFAULT_LOCALE]
  ),
  ...baseColors.map(
    (color) => color.name[locale] || color.name[DEFAULT_LOCALE]
  ),
];

const randomItem = (items) => items[Math.floor(Math.random() * items.length)];
const randomColor = () => randomItem(colors);
const randomBgColor = (color) => {
  return randomItem(color.validBgColors);
};

const randomWord = (locale) => randomItem(words(locale));

const randomWordExcluding = (wordsToExclude, locale) => {
  let newRandomWord = randomWord(locale);
  while (wordsToExclude.includes(newRandomWord)) {
    newRandomWord = randomWord(locale);
  }
  return newRandomWord;
};

const randomCard = (locale = "dev", text) => {
  const newColor = randomColor();
  const color = newColor;
  const bgColor = randomBgColor(newColor);
  const word = text || randomWord(locale);
  const answers = shuffleAnswers(
    [color.name[locale], bgColor.name[locale], word],
    locale
  );

  return {
    color,
    bgColor,
    word,
    answers,
  };
};

const getCorrectAnswer = (card, locale) => {
  const bgColor = card.bgColor.name[locale];
  const color = card.color.name[locale];
  const word = card.word;

  const isBadword =
    badwords.filter(
      (badword) => word === badword.name[locale] && word !== BW1.name[locale]
    ).length > 0;

  if (isBadword) {
    return word;
  }

  if (card.color.name[locale] === BLACK.name[locale]) {
    return bgColor;
  }

  return color;
};

const shuffleAnswers = (answers, locale) => {
  // replace duplicates
  const noDuplicates = [...new Set(answers)];

  if (noDuplicates.length < 3) {
    noDuplicates.push(randomWordExcluding(noDuplicates, locale));
  }

  for (let i = noDuplicates.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [noDuplicates[i], noDuplicates[j]] = [noDuplicates[j], noDuplicates[i]];
  }

  return noDuplicates;
};

const GameEngine = () => {
  const [locale, setLocale] = useState(DEFAULT_LOCALE);
  const initialCard = randomCard(locale, GAME_TITLE);

  const [time, setTime] = useState(DEFAULT_TIME);
  const [count, setCount] = useState(0);
  const [bestScore, setBestScore] = useState(0);
  const [gameOn, setGameOn] = useState(false);
  const [isAlive, setisAlive] = useState(true);
  const [card, setCard] = useState(initialCard);
  const [wrongAnswer, setWrongAnswer] = useState(null);

  useEffect(() => {
    if (gameOn) {
      if (time > 0) {
        setTimeout(() => setTime(time - 1), 1000);
      } else {
        // time === 0
        setGameOn(false);
        setBestScore(count > bestScore ? count : bestScore);
      }
    }
  }, [gameOn, time, bestScore, count]);

  const onStartGame = () => {
    setTime(DEFAULT_TIME);
    setGameOn(true);
    setisAlive(true);
    setWrongAnswer(null);
    setCount(0);
    setCard(randomCard(locale));
  };

  const onClickAnswer = (clickedAnswer) => {
    const correctAnswer = getCorrectAnswer(card, locale);
    if (correctAnswer === clickedAnswer) {
      setCount(count + 1);
      setCard(randomCard(locale));
    } else {
      setGameOn(false);
      setisAlive(false);
      setWrongAnswer({ clickedAnswer, correctAnswer });
      setBestScore(count > bestScore ? count : bestScore);
    }
  };

  const onChangeLanguage = (newLocale) => {
    setLocale(newLocale);
  };

  return (
    <Game
      gameOn={gameOn}
      isAlive={isAlive}
      onStartGame={onStartGame}
      locale={locale}
      card={card}
      answers={card.answers}
      wrongAnswer={wrongAnswer}
      count={count}
      time={time}
      bestScore={bestScore}
      onClickAnswer={onClickAnswer}
      onChangeLanguage={onChangeLanguage}
    />
  );
};

export default GameEngine;
