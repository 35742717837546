export const i18n = {
  START_TEXT: {
    "en": "Touch to start",
    "es": "Toca para empezar"
  },
  CLOSE: {
    "en": "Close",
    "es": "Cerrar"
  },
  START: {
    "en": "Start",
    "es": "Empezar"
  },
  RULES: {
    "en": "Rules",
    "es": "Reglas"
  },
  RULE: {
    "en": "Rule",
    "es": "Regla"
  },
  TIME: {
    "en": "Time",
    "es": "Tiempo"
  },
  SCORE: {
    "en": "Score",
    "es": "Puntos"
  },
  RECORD: {
    "en": "Record",
    "es": "Récord"
  },
  YOU_ANSWERED: {
    "en": "You answered",
    "es": "Respondiste"
  },
  SOLUTION: {
    "en": "Solution",
    "es": "Solución"
  }
}