const RED = {
  name: { en: "red", es: "rojo" },
  color: "red"
};

const YELLOW = {
  name: { en: "yellow", es: "amarillo" },
  color: "yellow"
};

const BLUE = {
  name: { en: "blue", es: "azul" },
  color: "deepskyblue"
};

const GREEN = {
  name: { en: "green", es: "verde" },
  color: "limegreen"
};

const PURPLE = {
  name: { en: "purple", es: "morado" },
  color: "purple"
};

const ORANGE = {
  name: { en: "orange", es: "naranja" },
  color: "orange"
};

const BLACK = {
  name: { en: "black", es: "negro" },
  color: "black"
};

RED.validBgColors = [YELLOW, BLUE, GREEN, ORANGE];
YELLOW.validBgColors = [RED, BLUE, GREEN, PURPLE, ORANGE];
BLUE.validBgColors = [RED, YELLOW, PURPLE, ORANGE];
GREEN.validBgColors = [RED, YELLOW, PURPLE, ORANGE];
PURPLE.validBgColors = [RED, YELLOW, BLUE, GREEN, ORANGE];
ORANGE.validBgColors = [RED, YELLOW, BLUE, GREEN, PURPLE];
BLACK.validBgColors = [RED, YELLOW, BLUE, GREEN, PURPLE, ORANGE];

export { RED, YELLOW, BLUE, GREEN, PURPLE, ORANGE, BLACK };
