import styled from "styled-components";

const Card = styled.div.attrs({
  "data-testid": "card"
})`
  max-width: 450px;
  height: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${props => props.bgColor};
  color: ${props => props.color};
  font-size: 48px;
  font-weight: bold;
  text-transform: uppercase;
  user-select: none;
  border-radius: 3px;
  box-shadow: 3px 3px 7px rgba(0,0,0,0.25);
  padding: 20px;
  text-align: center;
`

export const Solution = styled.p`
  font-size: 16px;
  margin: 0;
`

export const SmallCard = styled(Card)`
  width: 225px;
  height: 140px;
  padding: 8px;
  font-size: 28px;
`

export default Card;
