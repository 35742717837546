const BW1 = {
  name: { en: "fuck", es: "joder", dev: "fword" }
};

const BW2 = {
  name: { en: "asshole", es: "hostia", dev: "fword2" }
};

const BW3 = {
  name: { en: "cunt", es: "tonto", dev: "fword3" }
};

const BW4 = {
  name: { en: "pussy", es: "cabrón", dev: "fword4" }
};

export { BW1, BW2, BW3, BW4 };
