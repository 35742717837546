import React from "react";
import styled from "styled-components";
import "./reset.css";
import { i18n } from "./lib/misc";

import Button from "./components/Button";
import Card, { Solution } from "./components/Card";
import Answers from "./components/Answers";
import Counter from "./components/Counter";
import Rules from "./components/Rules";
import LanguageSwitcher from "./components/LanguageSwitcher";
import variables from "./lib/variables";

const Screen = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: ${variables.SPACE_UNIT}px;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => !props.isAlive && "red"};
`;

const Toolbar = styled.div`
  display: flex;
  margin-bottom: ${variables.SPACE_UNIT}px;
  justify-content: space-between;
  align-items: flex-start;
`;

const ToolbarRight = styled.div`
  display: flex;
`;

const CardWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media all and (orientation: landscape) {
    ${Card} {
      flex: 2;
      margin-right: ${variables.SPACE_UNIT}px;
    }

    ${Answers.Buttons}, ${Button} {
      flex: 1;
    }
  }

  @media all and (orientation: portrait) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    ${Card} {
      width: 100%;
      margin-bottom: ${variables.SPACE_UNIT}px;
    }

    ${Answers.Buttons}, ${Button} {
      width: 80%;
      & > ${Button} {
        width: 100%;
      }
    }
  }
`;

const GameBoard = ({
  card,
  answers,
  wrongAnswer,
  gameOn,
  locale,
  onClickAnswer,
  onStartGame,
}) => (
  <CardWrapper>
    <Card bgColor={card.bgColor.color} color={card.color.color}>
      {card.word}
      <div>
        <Solution>
          {wrongAnswer &&
            `${i18n.YOU_ANSWERED[locale]}: ${wrongAnswer.clickedAnswer}`}
        </Solution>
        <Solution>
          {wrongAnswer &&
            `${i18n.SOLUTION[locale]}: ${wrongAnswer.correctAnswer}`}
        </Solution>
      </div>
    </Card>
    {gameOn ? (
      <Answers
        card={card}
        locale={locale}
        onClickAnswer={onClickAnswer}
        answers={answers}
      />
    ) : (
      <Button onClick={onStartGame}>{i18n.START[locale]}</Button>
    )}
  </CardWrapper>
);

const Game = ({
  gameOn,
  isAlive,
  onStartGame,
  locale,
  card,
  answers,
  wrongAnswer,
  count,
  time,
  bestScore,
  onClickAnswer,
  onChangeLanguage,
}) => {
  return (
    <Screen isAlive={isAlive}>
      <Toolbar>
        <Counter
          count={count}
          time={time}
          bestScore={bestScore}
          locale={locale}
        />
        <ToolbarRight>
          <LanguageSwitcher onChange={onChangeLanguage} />
          <Rules locale={locale} />
        </ToolbarRight>
      </Toolbar>
      <GameBoard
        card={card}
        locale={locale}
        answers={answers}
        wrongAnswer={wrongAnswer}
        onStartGame={onStartGame}
        gameOn={gameOn}
        onClickAnswer={onClickAnswer}
      />
    </Screen>
  );
};

export default Game;
