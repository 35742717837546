import styled from "styled-components";

const Button = styled.button`
  background: #000000;
  color: #ffffff;
  padding: 14px;
  font-weight: bold;
  text-transform: uppercase;
  border: 0;
  border-radius: 2px;
`;

export default Button;
