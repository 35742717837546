import React, { useState } from "react";
import styled from "styled-components";

import { RULE_1, RULE_2, RULE_3, RULE_4 } from "../lib/rules";
import { RED, BLUE, YELLOW, GREEN, BLACK } from "../lib/colors";
import { BW1, BW2 } from "../lib/badwords";
import { i18n } from "../lib/misc";

import Button from "./Button";
import { SmallCard } from "./Card";

const RulesWrapper = styled.div``;

const RulesModal = styled.div`
  padding: 20px;
  background: #ffffff;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  text-align: center;

  ${SmallCard} {
    margin: auto;
  }
`;

const CloseButton = styled(Button)`
  position: fixed;
  top: 20px;
  right: 20px;
`;

const Rules = ({ locale, className }) => {
  const [visible, setVisibility] = useState(false);

  return (
    <RulesWrapper className={className}>
      <Button onClick={() => setVisibility(true)}>{i18n.RULES[locale]}</Button>
      {visible && (
        <RulesModal visible={visible}>
          <h3>{i18n.RULE[locale]} 1</h3>
          <p>{RULE_1[locale](BLUE.name[locale])}</p>
          <SmallCard bgColor={BLUE.color} color={BLACK.color}>
            {RED.name[locale]}
          </SmallCard>
          <h3>{i18n.RULE[locale]} 2</h3>
          <p>{RULE_2[locale](YELLOW.name[locale])}</p>
          <SmallCard bgColor={GREEN.color} color={YELLOW.color}>
            {BLACK.name[locale]}
          </SmallCard>
          <h3>{i18n.RULE[locale]} 3</h3>
          <p>{RULE_3[locale](locale, BW2.name[locale])}</p>
          <SmallCard bgColor={YELLOW.color} color={BLACK.color}>
            {BW2.name[locale]}
          </SmallCard>
          <h3>{i18n.RULE[locale]} 4</h3>
          <p>{RULE_4[locale](locale, RED.name[locale])}</p>
          <SmallCard bgColor={BLUE.color} color={RED.color}>
            {BW1.name[locale]}
          </SmallCard>
          <CloseButton onClick={() => setVisibility(false)}>
            {i18n.CLOSE[locale]}
          </CloseButton>
        </RulesModal>
      )}
    </RulesWrapper>
  );
};

export default Rules;
