import React from "react";
import styled from "styled-components";
import Button from "./Button";
import variables from "../lib/variables";

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: ${variables.SPACE_UNIT}px;
  }
`;

const AnswerButton = styled(Button)`
  order: ${(props) => props.order};
`;

const Answers = ({ answers, onClickAnswer }) => {
  return (
    <Buttons>
      <AnswerButton
        onClick={() => onClickAnswer(answers[0])}
      >
        {answers[0]}
      </AnswerButton>
      <AnswerButton onClick={() => onClickAnswer(answers[1])}>
        {answers[1]}
      </AnswerButton>
      <AnswerButton onClick={() => onClickAnswer(answers[2])}>
        {answers[2]}
      </AnswerButton>
    </Buttons>
  );
};

Answers.Buttons = Buttons;

export default Answers;
