import React from "react";
import styled from "styled-components";

const SelectLanguage = styled.select``;

const Language = ({ onChange, className }) => {
  return (
    <SelectLanguage
      onChange={e => onChange(e.target.value)}
      className={className}
    >
      <option value="es">Español 🇪🇸</option>
      <option value="en">English 🇬🇧</option>
    </SelectLanguage>
  );
};

export default Language;
